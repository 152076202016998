import React, { useState, useEffect, useMemo } from 'react'
import PrismicService from '../../services/PrismicService'
import { RichText } from 'prismic-reactjs'

const CreatorsGallery = (props) => {

	const [gallery, setGallery] = useState([])
	const [isLoading, setIsLoading] = useState(true)

	const Prismic = useMemo(() => {
		return new PrismicService()
	}, [])

	useEffect(() => {
		const fetchData = async () => {
			const response = await Prismic.getCreatorsGalleryList()
			if (response) {
				// console.log(response.results)
				setIsLoading(false)
				setGallery(response.results)
			}
		}
		fetchData()
		const fetchData2 = async () => {
			const response = await Prismic.getLiveStreamEvents()
			if (response) {
				console.log(response.results)
			}
		}
		fetchData2()
		
	}, [Prismic])

	const GalleryItem = (props) => {
		const { data } = props
		return (
			<div className={`shadow-md bg-white`}>
				<img src={data.gallery_image.url} alt={data.title} />
				<div className={`p-4`}>
					{RichText.render(data.description)}
				</div>
			</div>
		)
	}

	if (isLoading) {
		return <p className={`text-3xl`}>Loading ...</p>
	}

	if (gallery.length === 0 ) {
		return <p className={`text-3xl`}>There's nothing in the gallery yet, please check back again soon!</p>
	}

	return (
		<div className={`grid gap-4 grid-cols-1 md:grid-cols-3 xl:grid-cols-4`}>
			{gallery.map((item, index) => {
				return <GalleryItem data={item.data} key={index} />
			})}
		</div>
	)
}

export default CreatorsGallery

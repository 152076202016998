import Prismic from '@prismicio/client'

export default class PrismicService {
	apiEndpoint
	accessToken
	client

	constructor () {
		this.apiEndpoint = process.env.GATSBY_PRISMIC_API_ENDPOINT_BASE_URL
		this.accessToken = process.env.GATSBY_PRISMIC_ACCESS_TOKEN
		this.client = Prismic.client(this.apiEndpoint, { accessToken: this.accessToken })
	}

	getCreatorsGalleryList = () => {
		return this.client.query(
			Prismic.Predicates.at(
				`document.type`,
				`creators_gallery`,
				{
					pageSize: 100,
					orderings : '[document.first_publication_date desc]'
				}
			)
		)
	}

	getFestivalPlusVideoList = () => {
		return this.client.query(
			Prismic.Predicates.at(`document.type`, `festival_plus_video`)
		)
	}

}

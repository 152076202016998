import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout/Layout'
import BannerImageWithOverlay from '../../components/Page/BannerImageWithOverlay'
import CreatorsGallery from '../../components/Page/CreatorsGallery'

const creatorsGalleryPage = ({data}) => {

	return (
        <Layout>
			<BannerImageWithOverlay image={data.file.childImageSharp.gatsbyImageData}>
				<div className={`p-4 md:p-12 bg-blue-500 bg-opacity-95 text-white`}>
					<h2 className={`text-4xl xl:text-5xl mb-6`}>Creators Gallery</h2>
					<h3 className={`text-2xl xl:text-3xl`}>Look at all the fun Schools and Families had during the Boswell Book Festival!</h3>
				</div>
			</BannerImageWithOverlay>
			<section className="p-4 md:p-12 bg-boswell-handwriting">
				<CreatorsGallery />
			</section>
		</Layout>
    );
}

export const query = graphql`{
  file(relativePath: {eq: "general/Bozzy-16-9-003.jpg"}) {
    childImageSharp {
      gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
    }
  }
}
`

export default creatorsGalleryPage
